// src/main.js
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

const app = createApp(App)

// 添加自定义元素配置
// app.config.compilerOptions.isCustomElement = (tag) => tag === 'font'

app.use(router)
app.use(ElementPlus)
app.mount('#app')
